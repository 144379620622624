body, html {
    padding: 0;
    margin: 0;
    height: 100%;
	 overscroll-behavior: none;
	 overflow: hidden; /*changed hidden to auto or scroll */
}

/* for tutorial link only */
.links {
    position: fixed;
    bottom: 10px;
    right: 10px;
    font-size: 18px;
    font-family: sans-serif;
	 background-color: white;
	 padding: 10px;
}
a {
    text-decoration: none;
    color: rgb(243, 243, 243);
    margin-left: 1em;
}
a:hover {
    text-decoration: underline;
}
a img.icon {
    display: inline-block;
    height: 1em;
    margin: 0 0 -0.1em 0.3em;
}